import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private apiservice: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }

  getResourceList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    role?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/resource/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (role) {
      path += `&role=${role}`;
    }

    return this.apiservice.get(path);
  }

  getResourceListByProjectId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    productId: string
  ): Observable<any> {
    const path = `${this.baseUrl}/resource/list?projectId=${productId}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }

  getResourceListByAccountId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    accountId: string
  ): Observable<any> {
    const path = `${this.baseUrl}/resource/list?accountId=${accountId}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }

  getResourceListProjectByResourceId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    resourceId: string
  ): Observable<any> {
    const path = `${this.baseUrl}/resource/list/project/${resourceId}?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }

  createResource(formData: any): Observable<any> {
    let path = `${this.baseUrl}/resource`;
    return this.apiservice.post(path, formData);
  }

  getResourcByProjectId(accountId: string): Observable<any> {
    const path = `${this.baseUrl}/resource/list?accountId=${accountId}`;
    return this.apiservice.get(path);
  }

  getResourceById(id: string): Observable<any> {
    const path = `${this.baseUrl}/resource/${id}`;
    return this.apiservice.get(path);
  }

  getResourceDetails(
    id: string,
    type: 'core_teamID' | 'projectResourceID'
  ): Observable<any> {
    let path = '';
    if (type === 'core_teamID') {
      path = `${this.baseUrl}/customer/team-managemant/resource/${id}`;
    } else if (type === 'projectResourceID') {
      path = `${this.baseUrl}/customer/team-managemant/resource/${id}`;
    } else {
      throw new Error('Invalid ID type.');
    }
    return this.apiservice.get(path);
  }

  deleteResource(resourceID: string): Observable<any> {
    const path = `${this.baseUrl}/resource/${resourceID}`;
    return this.apiservice.delete(path);
  }

  updateResource(resourceData: any): Observable<any> {
    const url = `${this.baseUrl}/resource`;
    return this.apiservice.put(url, resourceData).pipe(
      catchError((error: any) => {
        console.error('Error updating resource:', error);
        throw error;
      })
    );
  }

  getStaticResourceList(): Observable<any> {
    const path = `${this.baseUrl}/master/resources`;
    return this.apiservice.get(path);
  }
}
